import AcademiesTable from "../component/AcademiesTable.jsx";
import React, { useState, useContext, useEffect } from "react";
import { TextField, Autocomplete, Button } from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication.jsx";
import useAxiosConfig from "../config/AxiosConfig";

const AcademiesProfile = () => {
  const { academies } = useContext(AuthContext);
  

  const headerData = [
    "Academy Name",
    "Sports Offered",
    "Coach/Trainer Name",
    "Actions",
  ];

  const [selectedAcademy, setSelectedAcademy] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedSport, setSelectedSport] = useState(null);
  const [sportsData, setSportsData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const axiosInstance = useAxiosConfig();

  useEffect(() => {
    const fetchAllSports = async () => {
      try {
        const response = await axiosInstance.get("/api/ground/get-all-sports");
        const sports = response.data.data;
        setSportsData(sports);
      } catch (error) {
        console.log("Error fetching sports", error);
      }
    };

    fetchAllSports();
  }, []);

  const buildQueryParams = () => {
    const params = new URLSearchParams();

    if (startDate) {
      params.append("startDate", startDate);
    }

    if (endDate) {
      params.append("endDate", endDate);
    }

    if (selectedAcademy && selectedAcademy.trim() !== "") {
      params.append("name", selectedAcademy);
    }

    if (selectedSport) {
      params.append("allowedSports", selectedSport);
    }

    const queryString = params.toString();

    return queryString;
  };

  useEffect(() => {
    const hasFilters = !!(
      selectedAcademy ||
      selectedSport ||
      startDate ||
      endDate
    );
    setIsFiltered(hasFilters);
  }, [selectedAcademy, selectedSport, startDate, endDate]);

  // Handle filter reset
  const handleResetFilters = () => {
    setSelectedAcademy(null);
    setSelectedSport(null);
    setStartDate("");
    setEndDate("");
    setIsFiltered(false);
  };

  return (
    <div className="p-4 w-full">
      <h2 className="text-xl font-bold mb-4">Academies</h2>
      <div className="flex gap-5">
        <Autocomplete
          options={academies}
          getOptionLabel={(option) => option.name}
          style={{ width: 300, marginBottom: "16px" }}
          value={
            academies.find((academy) => academy.name === selectedAcademy) ||
            null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Academy"
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps.endAdornment,
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedAcademy(newValue ? newValue.name : null);
          }}
          isOptionEqualToValue={(option, value) => option._id === value?._id}
        />
        {/* Coach Selector */}

        <Autocomplete
          options={sportsData}
          getOptionLabel={(option) => option.name}
          style={{ width: 300, marginBottom: "16px" }}
          value={
            sportsData.find((sport) => sport._id === selectedSport) || null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Sports"
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps.endAdornment,
              }}
            />
          )}
          onChange={(event, newValue) =>
            setSelectedSport(newValue ? newValue._id : null)
          }
          isOptionEqualToValue={(option, value) => option._id === value?._id}
        />

        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />
      </div>
      {/* Ground Selector */}
      <div className="flex gap-2">
        <Button variant="outlined" onClick={handleResetFilters}>
          Reset
        </Button>
      </div>
      <AcademiesTable
        headerData={headerData}
        isFiltered={isFiltered}
        queryParams={buildQueryParams()}
      />
    </div>
  );
};

export default AcademiesProfile;
