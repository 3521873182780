import React from "react";
import { useContext, useEffect, useState } from "react";

import { FaEye, FaRegEdit } from "react-icons/fa";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import useAxiosConfig from "../config/AxiosConfig";
import { AuthContext } from "../auth/ContextAuthentication";
import { useNavigate } from "react-router-dom";
import AcademiesModal from "../utils/AcademiesModal";
import EditAcademyModal from "../utils/Modals/EditAcademyModal";

const AcademiesTable = ({ headerData, isFiltered, queryParams }) => {
  const navigate = useNavigate();

  const axiosInstance = useAxiosConfig();

  const { academies, setAcademies } = useContext(AuthContext);
  const { academyDetails, setAcademyDetails } = useContext(AuthContext);

  const { setAcademyRegistrations } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  useEffect(() => {
    getAcademies();
  }, [queryParams, isFiltered]);

  const getAcademies = async () => {
    try {
      const url = isFiltered
        ? `api/admin/academy/get-all-academies?${queryParams}`
        : "api/admin/academy/get-all-academies";

      const response = await axiosInstance.get(url);
      setAcademies(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching academies:", error);
    }
  };

  const getAcademyRegistrations = async (academyId) => {
    console.log("Academy clicked:", academyId);
    try {
      const response = await axiosInstance.get(
        `api/admin/academy/get-academy-registrations/${academyId}`
      );
      setAcademyRegistrations(response?.data?.data || []);
    } catch (error) {
      console.error("Error adding ground:", error);
    } finally {
      navigate("/academy-registrations");
    }
  };

  const getAcademyDetails = async (academyId) => {
    console.log("Academy clicked:", academyId);
    try {
      const response = await axiosInstance.get(
        `api/admin/academy/get-academy-detail/${academyId}`
      );
      setAcademyDetails(response?.data?.data || []);
    } catch (error) {
      console.error("Error adding ground:", error);
    }
  };

  const editAcademyDetails = async (academyId, body) => {
    console.log("Academy clicked:", academyId);
    try {
      const response = await axiosInstance.put(
        `api/admin/academy/update-academy/${academyId}`,
        body
      );
      setAcademyDetails(response?.data?.data || []);
      await getAcademies();
    } catch (error) {
      console.error("Error adding ground:", error);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleEditModal = async (id) => {
    await getAcademyDetails(id);
    if (academyDetails) {
      setEditModalOpen(true);
    }
  };
  const handleEditCloseModal = () => setEditModalOpen(false);

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell
                  key={index}
                  align="left"
                  style={{ fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {academies?.map((academy, index) => (
              <TableRow key={index}>
                <TableCell
                  onClick={() => {
                    getAcademyRegistrations(academy._id);
                  }}
                  style={{ cursor: "pointer" }}
                  hover
                >
                  {academy?.name}
                </TableCell>
                <TableCell>
                  {academy?.allowedSports
                    ?.map((session) => session.name)
                    .join(" / ")}
                </TableCell>
                <TableCell>
                  {academy?.sessions
                    ?.map((session) => session.coachName)
                    .join(" / ")}
                </TableCell>
                <TableCell>
                  <IconButton>
                    <FaEye
                      onClick={() => {
                        handleOpen();
                        getAcademyDetails(academy._id);
                      }}
                      style={{
                        cursor: "pointer",
                        fontSize: 24,
                        color: "green",
                      }}
                    />
                  </IconButton>
                  <IconButton>
                    <FaRegEdit
                      onClick={() => {
                        handleEditModal(academy._id);
                      }}
                      style={{
                        fontSize: 24,
                        color: "purple",
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <AcademiesModal isOpen={open} isClose={() => setOpen(false)} />
      <EditAcademyModal
        isOpen={editModalOpen}
        isClose={handleEditCloseModal}
        handleSubmit={open}
        editAcademyDetails={editAcademyDetails}
      />
    </>
  );
};

export default AcademiesTable;
