import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import {
  Box,
  IconButton,
  MenuItem,
  Pagination,
  TextField,
  Autocomplete,
} from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { AuthContext } from "../auth/ContextAuthentication";
import Button from "@mui/material/Button";
import GroundProfileModal from "./GroundProfileModal";
import useAxiosConfig from "../config/AxiosConfig";
import EditGroundModal from "./EditGroundModal";
import CircleLoader from "./CircleLoader";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";

const GroundTable = ({ headerData, fetchGroundData, totalPages }) => {
  const axiosInstance = useAxiosConfig();
  const { groundData, setGroundData } = useContext(AuthContext);
  const { adminRole } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState(groundData);
  const [searchQuery, setSearchQuery] = useState("");
  const [cityFilter, setCityFilter] = useState("All");
  const [selectedGround, setSelectedGround] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [editGround, setEditGround] = useState(null);
  const [editManagersName, setEditManagersName] = useState([]);
  const { loading } = useContext(ErrorLoadingContext);
  const [selectedSport, setSelectedSport] = useState(null);
  const [sportsData, setSportsData] = useState([]);
  // const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    const fetchAllSports = async () => {
      try {
        const response = await axiosInstance.get("/api/ground/get-all-sports");
        const sports = response.data.data;
        setSportsData(sports);
      } catch (error) {
        console.log("Error fetching sports", error);
      }
    };

    fetchAllSports();
  }, []);

  useEffect(() => {
    console.log("filteredDataaaaaaaaa", page);
    fetchGroundData(page);
  }, [page]);

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle status filter change
  const handleCityFilterChange = (event) => {
    setCityFilter(event.target.value);
  };

  // Function to open the modal with ground details
  const handleViewClick = (ground) => {
    setSelectedGround(ground);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedGround(null);
  };

  // Function to open the delete confirmation modal
  const handleDeleteClick = (itemId) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  // Function to close the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };

  const handleEditClick = (data) => {
    setEditGround(data);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setEditGround(null);
  };

  // const getCurrentPageData = () => {
  //   const startIndex = (page - 1) * rowsPerPage;
  //   const endIndex = startIndex + rowsPerPage;
  //   return filteredData.slice(startIndex, endIndex);
  // };

  // const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (event, page) => {
    console.log("page.....", page);
    setPage(page);
  };

  // Function to handle the actual deletion
  const handleDelete = async () => {
    if (deleteItemId) {
      try {
        setIsDeleting(true); // Start the deletion process

        // Perform the deletion action using the deleteItemId
        await axiosInstance.put(`api/admin/deleteground/${deleteItemId}`);

        // Once the deletion is successful, update the GroundData state accordingly
        const response = await axiosInstance.get("api/admin/getgrounds");
        const updatedGroundData = response.data.grounds;

        setGroundData(updatedGroundData);

        // Close the delete confirmation modal
        handleCloseDeleteModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsDeleting(false); // End the deletion process
      }
    }
  };

  const updateGroundData = async (updatedGround) => {};

  useEffect(() => {
    const getManagers = async () => {
      try {
        const response = await axiosInstance.get("api/ground/managers");
        setEditManagersName(response?.data?.managers);
      } catch (error) {
        console.error("Error adding ground:", error);
      }
    };

    getManagers();
  }, [setEditManagersName]);

  useEffect(() => {
    console.log("groundData", groundData);
    console.log("searchQuery", searchQuery);
    console.log("cityFilter", cityFilter);
    let filtered = groundData;

    if (searchQuery) {
      filtered = filtered.filter((ground) =>
        ground.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (cityFilter !== "All") {
      filtered = filtered.filter((ground) => ground.city === cityFilter);
    }

    setFilteredData(filtered);
    setPage(1);
  }, [ searchQuery, cityFilter]);

  return (
    <>
      {/* Search and filter */}
      <div className="absolute top-20 right-[4%] flex flex-row justify-center gap-4">
        <Autocomplete
          options={sportsData}
          getOptionLabel={(option) => option.name}
          size="small"
          style={{ width: 300, marginBottom: "16px" }}
          value={
            sportsData.find((sport) => sport._id === selectedSport) || null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Sports"
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps.endAdornment,
              }}
            />
          )}
          onChange={(event, newValue) =>
            setSelectedSport(newValue ? newValue._id : null)
          }
          isOptionEqualToValue={(option, value) => option._id === value?._id}
        />

        <TextField
          type="text"
          label="Search by ground name"
          value={searchQuery}
          onChange={handleSearchInputChange}
          size="small"
        />
        <TextField
          type="text"
          value={cityFilter}
          label="City"
          size="small"
          sx={{ maxWidth: "180px" }}
          select
          onChange={handleCityFilterChange}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Islamabad">Islamabad</MenuItem>
          <MenuItem value="Lahore">Lahore</MenuItem>
        </TextField>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          maxWidth: {
            sm: "98%",
            // md: "80%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "76vh",
            sm: "90vh",
          },
          overflowY: "auto",
          overflowX: "scroll",
          position: "relative",
          top: "4rem",
          marginX: "auto",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <CircleLoader />
          ) : (
            <TableBody>
              {filteredData.map((data) => (
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {data.name}
                  </TableCell>
                  {/* <TableCell align="left">{data.noOfPlayers}</TableCell> */}
                  <TableCell align="left">{data.noOfFields}</TableCell>
                  {/* <TableCell align="left">{data.dimensions}</TableCell> */}
                  {/* <TableCell align="left">{data.city}</TableCell> */}
                  <TableCell align="left">
                    {data.isDeleted ? (
                      <IconButton sx={{ color: "green" }}>
                        <DoneOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: "red" }}>
                        <ClearOutlinedIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <IconButton onClick={() => handleViewClick(data)}>
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleEditClick(data)}
                        disabled={adminRole !== "Super Admin"}
                        style={{
                          cursor:
                            adminRole === "Super Admin"
                              ? "pointer"
                              : "not-allowed",
                          opacity: adminRole === "Super Admin" ? 1 : 0.5,
                        }}
                      >
                        <FaRegEdit
                          style={{
                            fontSize: 24,
                            color:
                              adminRole === "Super Admin" ? "purple" : "grey",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(data._id)}
                        disabled={adminRole !== "Super Admin"}
                        style={{
                          cursor:
                            adminRole === "Super Admin"
                              ? "pointer"
                              : "not-allowed",
                          opacity: adminRole === "Super Admin" ? 1 : 0.5,
                        }}
                      >
                        <FaTrash
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: adminRole === "Super Admin" ? "red" : "grey",
                          }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {filteredData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>

        {selectedGround && (
          <GroundProfileModal
            ground={selectedGround}
            setSelectedGround={setSelectedGround}
            handleClose={handleCloseModal}
          />
        )}
        {editGround && (
          <EditGroundModal
            ground={editGround}
            handleClose={handleCloseEditModal}
            handleUpdate={updateGroundData}
            editManagersName={editManagersName}
            adminRole={adminRole}
          />
        )}
        {!loading && filteredData.length > 0 && (
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              padding: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </TableContainer>

      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3 max-[500px]:relative"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
            "@media (maxWidth: 600px)": {
              maxWidth: 250,
            },
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className="text-red-700">Confirm Deletion!</h2>
            {isDeleting ? (
              <p className="sm:text-sm">Deleting...</p>
            ) : (
              <p className="sm:text-sm">
                Are you sure you want to delete this record?
              </p>
            )}
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
                disabled={isDeleting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroundTable;
