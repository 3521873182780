import React from "react";
import GroundTable from "../component/GroundTable";

const SportsProfile = () => {
  const headerData = [
    "Name",
    "No of Players",
    "No of Fields",
    "Dimensions",
    "City",
    "IsDeleted",
  ];
  
  return (
    <>
      <GroundTable headerData={headerData} />
    </>
  );
};

export default SportsProfile;
