import { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/ContextAuthentication";

let isSecuredSsl = true;
let baseUrl = isSecuredSsl
  ? process.env.REACT_APP_API_URL
  : "http://35.155.14.242/api/";

const useAxiosConfig = () => {
  const { setAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
    },
  });

  axiosInstance.interceptors.request.use((config) => {
    const bearerToken = localStorage.getItem("access_token");

    if (bearerToken) {
      config.headers.Authorization = `Bearer ${bearerToken}`;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      // Return the response if successful
      return response;
    },
    (error) => {
      // Handle 401 errors
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access - redirecting to login.");
        localStorage.removeItem("access_token");
        localStorage.removeItem("admin_role");
        setAuthenticated(false);
        navigate("/login");
      } else {
        // Handle other errors
        return Promise.reject(error);
      }
    }
  );

  return axiosInstance;
};

export default useAxiosConfig;
