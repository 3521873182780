import { useContext } from "react";
import { Modal, Box, Typography, Button, Divider } from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication";

const AcademiesModal = ({ isOpen, isClose }) => {
  const { academyDetails } = useContext(AuthContext);

  return (
    <div>
      <Modal open={isOpen} onClose={isClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h5"
            component="h2"
            sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}
          >
            Academy Details
          </Typography>

          <Divider sx={{ mb: 3 }} />

          <Typography sx={{ mb: 2 }}>
            <strong>Academy Name:</strong> {academyDetails?.name}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Location:</strong> {academyDetails?.name}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Opening Time:</strong> {academyDetails?.openTime}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Closing Time:</strong> {academyDetails?.closeTime}
          </Typography>
          <Typography sx={{ mb: 2 }}>
            <strong>Registration Fee:</strong> {academyDetails?.registrationFee}{" "}
            Rs
          </Typography>

          <Typography variant="h6" sx={{ mt: 3, mb: 2, fontWeight: "bold" }}>
            Sessions
          </Typography>

          {academyDetails?.sessions?.map((session, index) => (
            <Box
              key={index}
              sx={{ mb: 3, pl: 2, borderLeft: "2px solid #1976d2" }}
            >
              <Typography sx={{ mb: 1 }}>
                <strong>Session Name:</strong> {session?.sessionName}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <strong>Available Days:</strong>{" "}
                {session?.availableDays.join(", ")}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <strong>Duration:</strong> {session?.duration}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <strong>Start Time:</strong> {session?.timeSlotStart}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <strong>End Time:</strong> {session?.timeSlotEnd}
              </Typography>
              <Typography sx={{ mb: 1 }}>
                <strong>Monthly Fee:</strong> {session?.monthlyFee} Rs
              </Typography>
            </Box>
          ))}

          <Button
            variant="contained"
            onClick={isClose}
            sx={{
              mt: 3,
              bgcolor: "#1976d2",
              ":hover": { bgcolor: "#115293" },
              display: "block",
              ml: "auto",
              mr: "auto",
            }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default AcademiesModal;
