import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import { MenuItem, Select, Grid, InputLabel, Checkbox, FormControlLabel } from "@mui/material";
import useAxiosConfig from "../config/AxiosConfig";
import { AuthContext } from "../auth/ContextAuthentication";

// Options for the dropdown
const playerOptions = Array.from({ length: 11 }, (_, i) => `${i + 1} V ${i + 1}`);

const EditGroundModal = ({ ground, handleClose, handleUpdate, editManagersName }) => {
  const axiosInstance = useAxiosConfig();
  const { groundData, setGroundData } = useContext(AuthContext);
  console.log("grounddata", ground)
  const [managedBy, setManagedBy] = useState(ground.managedBy)
  
  const [editedGround, setEditedGround] = useState({
    ...ground,

    properties: {
      ...ground.properties,
      footballs: ground.properties?.footballs === "true" || ground.properties?.footballs === true,
      bibs: ground.properties?.bibs === "true" || ground.properties?.bibs === true,
      floodLights: ground.properties?.floodLights === "true" || ground.properties?.floodLights === true,
      restRooms: ground.properties?.restRooms === "true" || ground.properties?.restRooms === true,
      parkingArea: ground.properties?.parkingArea === "true" || ground.properties?.parkingArea === true,
      securityCameras: ground.properties?.securityCameras === "true" || ground.properties?.securityCameras === true,
      seatingArea: ground.properties?.seatingArea === "true" || ground.properties?.seatingArea === true,
    },
  });

  const [isUpdating, setIsUpdating] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [updatedMembers, setUpdatedMembers] = useState([]);
  const [latitude, setLatitude] = useState(editedGround?.loc?.coordinates[1] || "");
  const [longitude, setLongitude] = useState(editedGround?.loc?.coordinates[0] || "");

  useEffect(() => {
    if (editManagersName && editManagersName?.length > 0) {
      const updatedManagers = editManagersName.filter((manager) => manager.fullName !== undefined);
      setUpdatedMembers(updatedManagers);
    }
  }, [setUpdatedMembers]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    if (name in editedGround.properties) {
      setEditedGround((prevData) => ({
        ...prevData,
        properties: {
          ...prevData.properties,
          [name]: fieldValue,
        },
      }));
    } else {
      setEditedGround((prevData) => ({
        ...prevData,
        [name]: fieldValue,
      }));
    }
  };

  const handleManagedByInputChange = (e) => {
    const { value } = e.target;
    console.log("value" , value)
    setManagedBy(value);
  };

  const handleUpdateClick = async () => {
    let coordinates = [];
    parseInt(longitude);
    parseInt(latitude);
    coordinates.push(longitude);
    coordinates.push(latitude);

    let data = {
      ...editedGround,
      coordinates,

      duration: Number(editedGround.duration), 
      properties: {
        ...editedGround.properties,
        footballs: editedGround.properties.footballs ? "true" : "false",
        bibs: editedGround.properties.bibs ? "true" : "false",
        floodLights: editedGround.properties.floodLights ? "true" : "false",
        restRooms: editedGround.properties.restRooms ? "true" : "false",
        parkingArea: editedGround.properties.parkingArea ? "true" : "false",
        securityCameras: editedGround.properties.securityCameras ? "true" : "false",
        seatingArea: editedGround.properties.seatingArea ? "true" : "false",
      },
      managedBy: managedBy
    };
    console.log("data", data)
    try {
      setIsUpdating(true);
      const response = await axiosInstance.put(
        `api/admin/updateGround/${editedGround._id}`,
        data
      );
      handleUpdate(editedGround);
      setUpdateStatus({ message: "Ground updated successfully", success: true });
      const groundResponse = await axiosInstance.get("api/admin/getgrounds");
      const updatedGroundData = groundResponse.data.grounds;
      setGroundData(updatedGroundData);

      if (handleClose) {
        handleClose();
      }
    } catch (error) {
      console.error("Error updating ground:", error);
      setUpdateStatus({ message: "Failed to add ground, some fields are empty", success: false });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Ground</DialogTitle>
      <DialogContent>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                id="location"
                name="location"
                label="Map Location"
                variant="outlined"
                value={editedGround?.location}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="city"
                name="city"
                label="City"
                variant="outlined"
                value={editedGround?.city}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="latitude"
                name="coordinates"
                label="Latitude"
                variant="outlined"
                type="number"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                margin="normal"
                fullWidth
              />
              {latitude > 99 && (
                <span style={{ fontSize: "10px", color: "red" }}>
                  Please enter a valid latitude between -99 and 99
                </span>
              )}
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="longitude"
                name="coordinates"
                label="Longitude"
                variant="outlined"
                type="number"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                margin="normal"
                fullWidth
              />
              {longitude > 99 && (
                <span style={{ fontSize: "10px", color: "red" }}>
                  Please enter a valid longitude between -99 and 99
                </span>
              )}
            </Grid>
            <Grid item xs={12}>
              <InputLabel id="owner-label">Managed By</InputLabel>
              <Select
                id="managed_by"
                name="Managed By"
                value={managedBy}
                onChange={handleManagedByInputChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Managed By
                </MenuItem>
                {updatedMembers?.map((manager) => (
                 <MenuItem key={manager._id} value={manager.fullName}>
                 {manager.fullName}
               </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.footballs}
                    onChange={handleInputChange}
                    name="footballs"
                    color="primary"
                  />
                }
                label="Footballs"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.bibs}
                    onChange={handleInputChange}
                    name="bibs"
                    color="primary"
                  />
                }
                label="Bibs"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.floodLights}
                    onChange={handleInputChange}
                    name="floodLights"
                    color="primary"
                  />
                }
                label="Flood Lights"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.restRooms}
                    onChange={handleInputChange}
                    name="restRooms"
                    color="primary"
                  />
                }
                label="Rest Rooms"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.parkingArea}
                    onChange={handleInputChange}
                    name="parkingArea"
                    color="primary"
                  />
                }
                label="Parking Area"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.securityCameras}
                    onChange={handleInputChange}
                    name="securityCameras"
                    color="primary"
                  />
                }
                label="Security Cameras"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedGround?.properties?.seatingArea}
                    onChange={handleInputChange}
                    name="seatingArea"
                    color="primary"
                  />
                }
                label="Seating Area"
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel id="noOfPlayers-label" sx={{ fontSize: "0.7rem" }}>No Of Players</InputLabel>
              <Select
                labelId="noOfPlayers-label"
                id="noOfPlayers"
                name="noOfPlayers"
                value={editedGround?.noOfPlayers || ""}
                onChange={handleInputChange}
                fullWidth
              >
                {playerOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="noOfFields"
                name="noOfFields"
                label="No Of Fields"
                variant="outlined"
                type="number"
                value={editedGround?.noOfFields}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="dimensions"
                name="dimensions"
                label="Dimensions"
                variant="outlined"
                value={editedGround?.dimensions}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="pricePerBookingDay"
                name="pricePerBookingDay"
                label="Price per Booking Day"
                variant="outlined"
                type="number"
                value={editedGround?.price?.perBooking?.day}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="pricePerBookingNight"
                name="pricePerBookingNight"
                label="Price per Booking Night"
                variant="outlined"
                type="number"
                value={editedGround?.price?.perBooking?.night}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          {updateStatus !== null && (
            <Box mt={2}>
              <Snackbar
                open={true}
                autoHideDuration={5000}
                onClose={() => setUpdateStatus(null)}
              >
                <Alert
                  severity={updateStatus.success ? "success" : "error"}
                  onClose={() => setUpdateStatus(null)}
                >
                  {updateStatus.message}
                </Alert>
              </Snackbar>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdateClick}
          disabled={isUpdating}
        >
          {isUpdating ? "Updating..." : "Update Ground"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          disabled={isUpdating}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditGroundModal;
