import React, { useContext, useEffect } from "react";
import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";

const PlayersProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { setAllPlayerData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const headerData = [
    "",
    "FullName",
    "Email",
    "PhoneNo",
    "Role",
    "IsSuspended",
    "IsVerified",
  ];

  useEffect(() => {
    const fetchAllPlayers = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("api/admin/get-all-users");
        setAllPlayerData(response.data.users);
        setLoading(false);
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchAllPlayers();
  }, [setAllPlayerData]);

  return (
    <>
      <TableData headerData={headerData} />
    </>
  );
};

export default PlayersProfile;