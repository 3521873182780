// PlayerProfileModal.js
import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Alert, IconButton, Snackbar } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import useAxiosConfig from "../config/AxiosConfig";
// import axios from "axios";
// import groundImg from '../assets/images/ground.jpg'
const GroundProfileModal = ({
  ground,
  handleClose,
  setSelectedGround,
  adminRole,
}) => {
  const axiosInstance = useAxiosConfig();
  // Add conditional checks to ensure properties
  const properties = ground?.properties || {};
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [check, setCheck] = useState(false);
  // const [newImages, setNewImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const enlargeImage = (image) => {
    setSelectedImage(image);
    setEnlargedImage(image);
  };
  const closeEnlarged = () => {
    setEnlargedImage(null);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const addImage = async () => {
    // console.log("Add");
    //   const response = await axios.post(
    //     'https://api.maidanofficial.com/api/upload/image',
    //     formData,
    //     {
    //         headers: {
    //             'Content-Type': 'multipart/form-data',
    //         },
    //     }
    // );
  };
  const removeImage = async () => {
    try {
      const response = await axiosInstance.delete(
        `/api/admin/deletegroundimages/${ground._id}`,
        {
          data: {
            imageIds: ground?.images,
          },
        }
      );
      if (response.data.status == true) {
        setOpenSnackbar(true);
        setUpdateStatus({
          message: "Photos deleted successfully",
          success: true,
        });
        const groundResponse = await axiosInstance.get("api/admin/getgrounds");
        const updatedGroundData = groundResponse.data.grounds;
        setSelectedGround(updatedGroundData);
      }
    } catch (error) {
      setUpdateStatus({
        message: "Something went wrong while deleting photos",
        success: false,
      });
    }
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          severity={updateStatus?.success ? "success" : "error"}
          onClose={handleSnackbarClose}
        >
          {updateStatus?.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={!!ground}
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        minHeight="lg"
      >
        <DialogTitle sx={{ textAlign: "center" }}>Ground Profile</DialogTitle>
        {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            maxWidth: "500px",
            maxHeight: "250px",
            borderRadius: "5px",
            overflow: "hidden",
            marginBottom: "0.5rem",
          }}
        >
          <img
            src={`https://maidan-s3.s3.us-west-2.amazonaws.com/${ground.images[0]}`}
            alt="User Profile"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
       
        </div>
      </div> */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          {ground?.images?.map((image, index) => (
            <div
              key={index}
              style={{
                width: "calc(25% - 10px)",
                maxHeight: "250px",
                borderRadius: "5px",
                overflow: "hidden",
                marginBottom: "0.5rem",
              }}
              onClick={() => enlargeImage(image)}
            >
              <img
                src={`https://maidan-s3.s3.us-west-2.amazonaws.com/${image}`}
                alt={`Image ${index}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  cursor: "pointer",
                }}
              />
            </div>
          ))}
          {enlargedImage && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
              onClick={closeEnlarged}
            >
              <img
                src={`https://maidan-s3.s3.us-west-2.amazonaws.com/${enlargedImage}`}
                alt="Enlarged"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
        </div>
        <DialogContent
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>Ground ID:</strong> {ground?._id}
            </div>
            <div>
              <strong>Managed By</strong> {ground?.managedBy}
            </div>
            <div>
              <strong>Full Name:</strong> {ground?.name}
            </div>
            <div>
              <strong>Day Match Price:</strong> {ground?.price?.perBooking?.day}
            </div>
            <div>
              <strong>Night Match Price:</strong>{" "}
              {ground?.price?.perBooking?.night}
            </div>
            <div>
              <strong>No of Players</strong> {ground?.noOfPlayers}
            </div>
            <div>
              <strong>No of Fields</strong> {ground?.noOfFields}
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>Flood Lights:</strong>{" "}
              {properties?.floodLights ? "Yes" : "No"}
            </div>
            <div>
              <strong>Rest Rooms:</strong>{" "}
              {properties?.restrooms ? "Yes" : "No"}
            </div>
            <div>
              <strong>Parking Area:</strong>{" "}
              {properties?.parkingArea ? "Yes" : "No"}
            </div>
            <div>
              <strong>Security Cameras:</strong>{" "}
              {properties?.securityCameras ? "Yes" : "No"}
            </div>
            <div>
              <strong>Seating Area:</strong>{" "}
              {properties?.seatingArea ? "Yes" : "No"}
            </div>
            <div>
              <strong>Footballs:</strong> {properties?.footballs ? "Yes" : "No"}
            </div>
            <div>
              <strong>Bibs:</strong> {properties?.bibs ? "Yes" : "No"}
            </div>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>Dimensions:</strong> {ground?.dimensions}
            </div>
            <div>
              <strong>Address:</strong> {ground?.address}
            </div>
            <div>
              <strong>City:</strong> {ground?.city}
            </div>
            <div>
              <strong>Latitude</strong> {ground?.loc?.coordinates[1]}
            </div>
            <div>
              <strong>Longitude</strong> {ground?.loc?.coordinates[0]}
            </div>
            <div>
              <strong>IsDeleted:</strong>{" "}
              {ground.isDeleted ? (
                <IconButton sx={{ color: "green" }}>
                  <DoneOutlineIcon />
                </IconButton>
              ) : (
                <IconButton sx={{ color: "red" }}>
                  <ClearOutlinedIcon fontSize="medium" />
                </IconButton>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={addImage}
            disabled={adminRole !== "Super Admin"}
            sx={{
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
              "&:hover": {
                backgroundColor:
                  adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
                boxShadow: "none",
              },
              cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
            }}
          >
            Add Image
          </Button>
          <Button
            variant="contained"
            onClick={removeImage}
            disabled={(adminRole !== "Super Admin") !== "Super Admin"}
            sx={{
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
              "&:hover": {
                backgroundColor:
                  adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
                boxShadow: "none",
              },
              cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
            }}
          >
            Remove Image
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: "#0C7240",
              color: "white",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "#0C7240",
                boxShadow: "none",
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default GroundProfileModal;
