import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  IconButton,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { IoCloseSharp } from "react-icons/io5";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";

const BookingTable = ({ headerData, isLoading, groundMap }) => {
  const axiosInstance = useAxiosConfig();
  const [cancelBookingId, setCancelBookingId] = useState(null);
  const [bookingStatus, setBookingStatus] = useState(null);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const { bookingData, setBookingData } = useContext(AuthContext);
  const { adminRole } = useContext(AuthContext);


  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });
  };

  const handleCancelClick = (itemId, status) => {
    setCancelBookingId(itemId);
    setBookingStatus(status);
    setIsCancelModalOpen(true);
  };

  const handleCloseCancelModal = () => {
    setCancelBookingId(null);
    setBookingStatus(null);
    setIsCancelModalOpen(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbarInfo((prev) => ({ ...prev, open: false }));
  };

  const handleCancel = async () => {
    if (cancelBookingId) {
      try {
        setIsCanceling(true);

        await axiosInstance.post(
          `api/admin/cancel-booking-admin/${cancelBookingId}`
        );

        const response = await axiosInstance.get("api/admin/getgrounds");

        setBookingData((prevData) =>
          prevData.map((booking) =>
            booking._id === cancelBookingId
              ? { ...booking, bookingStatus: "canceled" }
              : booking
          )
        );

        setSnackbarInfo({
          open: true,
          message: "Booking successfully canceled.",
          severity: "success",
        });

        handleCloseCancelModal();
      } catch (error) {
        console.error(error);

        setSnackbarInfo({
          open: true,
          message: "Failed to cancel booking. Please try again.",
          severity: "error",
        });
      } finally {
        setIsCanceling(false);
      }
    }
  };

  // const getGroundName = (groundId) => groundMap?.[groundId]?.name || "N/A";
  // const getFieldName = (groundId, fieldId) =>
  //   groundMap?.[groundId]?.fields?.[fieldId] || "N/A";

  const renderTableContent = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={headerData.length + 1} align="center">
            <CircularProgress />
          </TableCell>
        </TableRow>
      );
    }

    if (!bookingData || bookingData.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={headerData.length + 1} align="center">
            No bookings available
          </TableCell>
        </TableRow>
      );
    }

    return bookingData.map((booking) => {
      const discount = booking.promoCode
        ? (parseFloat(booking.bookingFee) * booking.promoCode.discount) / 100
        : 0;
      const discountedFee = booking.bookingFee
        ? parseFloat(booking.bookingFee) - discount
        : 0;
      return (
        <TableRow
          key={booking._id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell
            sx={{
              width: "150px",
              whiteSpace: "nowrap",
              fontWeight: "bold",
              fontStyle: "italic",
            }}
          >
            {booking.owner?.fullName || "N/A"}
          </TableCell>
          <TableCell sx={{ width: "150px", whiteSpace: "nowrap" }}>
            {booking.owner?.phone_no || "N/A"}
          </TableCell>
          <TableCell sx={{ width: "180px", whiteSpace: "nowrap" }}>
            {formatDate(booking?.date)}
          </TableCell>
          <TableCell sx={{ width: "180px", whiteSpace: "nowrap" }}>
            {booking?.ground?.name}
          </TableCell>
          <TableCell sx={{ width: "180px", whiteSpace: "nowrap" }}>
            {booking?.fieldData?.name}
          </TableCell>
          <TableCell
            sx={{ width: "180px", whiteSpace: "nowrap" }}
          >{`${formatTime(booking.startTime)} - ${formatTime(
            booking.endTime
          )}`}</TableCell>
          <TableCell
            sx={{
              width: "150px",
              whiteSpace: "nowrap",
              fontStyle: "italic",
              backgroundColor: booking.AdvanceAmount
                ? "rgba(0, 255, 0, 0.1)"
                : "transparent",
            }}
          >
            {booking?.paidAmount || "N/A"}
          </TableCell>
          <TableCell
            sx={{
              width: "150px",
              whiteSpace: "nowrap",
              fontStyle: "italic",
              backgroundColor: booking.AdvanceAmount
                ? "rgba(255, 0, 0.1)"
                : "transparent",
            }}
          >
            {booking.paidAmount
              ? (discountedFee - parseFloat(booking.paidAmount)).toFixed(2)
              : "N/A"}
          </TableCell>
          <TableCell sx={{ width: "150px", whiteSpace: "nowrap" }}>
            {booking.promoCode?.code || "N/A"}
          </TableCell>
          <TableCell sx={{ width: "200px", whiteSpace: "nowrap" }}>
            {booking.groundOwner?.fullName || "N/A"}
          </TableCell>
          <TableCell sx={{ width: "200px", whiteSpace: "nowrap" }}>
            {booking.groundOwner?.phone_no || "N/A"}
          </TableCell>
          <TableCell sx={{ width: "120px", whiteSpace: "nowrap" }}>
            {booking.bookingStatus || "N/A"}
          </TableCell>
          <TableCell sx={{ width: "150px", whiteSpace: "nowrap" }}>
            <IconButton
              onClick={() =>
                handleCancelClick(booking._id, booking.bookingStatus)
              }
              disabled={adminRole !== "Super Admin"}
              style={{
                cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
                opacity: adminRole === "Super Admin" ? 1 : 0.5,
              }}
            >
              <IoCloseSharp
                style={{
                  cursor: "pointer",
                  fontSize: 24,
                  color: adminRole === "Super Admin" ? "red" : "grey",
                }}
              />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: {
            sm: "100%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "75vh",
            sm: "90vh",
          },
          overflowY: "auto",
          overflowX: "scroll",
          position: "relative",
        }}
      >
        <Table aria-label="booking table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: "bold",
                    width: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableContent()}</TableBody>
        </Table>
      </TableContainer>

      {/* Cancel Modal */}
      {isCancelModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3 max-[500px]:relative"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
            "@media (maxWidth: 600px)": {
              maxWidth: 250,
            },
          }}
        >
          <div className="flex flex-col gap-3">
            {bookingStatus === "active" ? (
              <h2 className="text-red-700">Confirm Cancellation!</h2>
            ) : (
              <h2 className="text-red-700">Already Canceled!</h2>
            )}

            {bookingStatus === "active" &&
              (isCanceling ? (
                <p className="sm:text-sm">Deleting...</p>
              ) : (
                <p className="sm:text-sm">
                  Are you sure you want to cancel the booking?
                </p>
              ))}

            <div className="flex items-center justify-end w-full gap-4 mt-3">
              <Button
                variant="contained"
                color="error"
                onClick={handleCloseCancelModal}
                size="small"
                disabled={isCanceling}
              >
                Close
              </Button>
              {bookingStatus === "active" && (
                <Button
                  onClick={handleCancel}
                  size="small"
                  variant="contained"
                  sx={{ cursor: "pointer" }}
                  disabled={isCanceling}
                >
                  {isCanceling ? "Canceling..." : "Confirm"}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Snackbar for cancellation success or failure */}
      <Snackbar
        open={snackbarInfo.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarInfo.severity}
          sx={{ width: "100%" }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BookingTable;
