import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
  Typography,
  Snackbar,
  Alert,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  Chip,
  CircularProgress,
} from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SportsFootballOutlinedIcon from "@mui/icons-material/SportsFootballOutlined";
import useAxiosConfig from "../config/AxiosConfig";

const CreatePromoForm = () => {
  const [promoName, setPromoName] = useState("");
  const [discount, setDiscount] = useState("");
  const [selectedGrounds, setSelectedGrounds] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [expirationDate, setExpirationDate] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [grounds, setGrounds] = useState([]);
  const [isLoadingGrounds, setIsLoadingGrounds] = useState(false);

  const axiosInstance = useAxiosConfig();

  useEffect(() => {
    fetchGrounds();
  }, []);

  const fetchGrounds = async () => {
    setIsLoadingGrounds(true);
    try {
      let allGrounds = [];
      let currentPage = 1;
      let hasNextPage = true;

      while (hasNextPage) {
        const response = await axiosInstance.get(
          `api/admin/getGrounds?page=${currentPage}`
        );
        allGrounds = [...allGrounds, ...response.data.grounds];

        // Check if there's a next page
        hasNextPage = currentPage < response.data.totalPages;
        currentPage++;
      }

      setGrounds(allGrounds);
    } catch (error) {
      console.error("Error fetching grounds:", error);
      setErrorMessage("Error fetching grounds");
    } finally {
      setIsLoadingGrounds(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      code: promoName,
      discount: parseInt(discount),
      startDate: formatDate(startDate),
      expiryDate: formatDate(expirationDate),
      groundIds: selectedGrounds.map((ground) => ground._id),
    };

    try {
      // Create new promotion
      const response = await axiosInstance.post(
        "api/admin/create-promo-code",
        data
      );
      if (
        response.status === 201 ||
        (response.status === 200 && response.data.status === true)
      ) {
        setSuccessMessage("Promo code created successfully");
        clearForm();
      } else {
        setErrorMessage(response.data.message || "Error creating promo code");
      }
    } catch (error) {
      console.error("Error creating/updating promo code:", error);
      setErrorMessage(
        error.response?.data?.message || "Error creating/updating promo code"
      );
    }
  };

  const clearForm = () => {
    setPromoName("");
    setDiscount("");
    setSelectedGrounds([]);
    setStartDate("");
    setExpirationDate("");
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
  };

  return (
    <Box
      sx={{
        maxWidth: 400,
        mx: "auto",
        p: 2,
        boxShadow: 3,
        borderRadius: 2,
        my: "auto",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
        Create Promotion
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Promo Name"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <DescriptionOutlinedIcon />
              </InputAdornment>
            ),
          }}
          value={promoName}
          onChange={(e) => setPromoName(e.target.value.toUpperCase())}
          margin="normal"
          required
        />
        <TextField
          fullWidth
          label="Enter % off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalOfferOutlinedIcon />
              </InputAdornment>
            ),
          }}
          type="number"
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          margin="normal"
          required
        />
        <Autocomplete
          multiple
          options={grounds}
          getOptionLabel={(option) => option.name}
          value={selectedGrounds}
          onChange={(event, newValue) => {
            setSelectedGrounds(newValue);
          }}
          loading={isLoadingGrounds}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose grounds"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <LocationOnOutlinedIcon />
                    </InputAdornment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          margin="normal"
          fullWidth
        />
        <TextField
          fullWidth
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          fullWidth
          label="Expiration Date"
          type="date"
          value={expirationDate}
          onChange={(e) => setExpirationDate(e.target.value)}
          margin="normal"
          InputLabelProps={{ shrink: true }}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="success"
          fullWidth
          sx={{ mt: 2 }}
        >
          Save
        </Button>
      </form>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CreatePromoForm;
