import React, { useContext, useEffect, useState } from "react";
// import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import PaymentTable from "../component/PaymentTable";
import { TextField, Button } from "@mui/material";
const headerData = [
  "FullName",
  "Type",
  "Amount",
  "Payment Type",
  "Wallet Balance",
  "Phone Number",
  "Transaction Date",
  "Match Date",
  "Match Time",
];

const PaymentVerfication = () => {
  const axiosInstance = useAxiosConfig();
  const { setPaymentTransactions } = useContext(AuthContext);
  const { setPaymentPagination } = useContext(AuthContext);
  const [datafetched, setDataFetched] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  useEffect(() => {
    const fetchApi = async () => {

      try {
        const response = await axiosInstance.get(
          `api/admin/payments/get-all-transactions?startDate=${startDate}&endDate=${endDate}`
        );

        setPaymentTransactions(response.data.transactions);
        const { currentPage, totalPages } = response.data;

        setPaymentPagination({
          //   "limit" : limit,
          currentPage: currentPage,
          //   "status":status,
          totalPages: totalPages,
          //   "totalUser":totalUsers
        });
        setDataFetched(true);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, [setPaymentTransactions, setPaymentPagination, startDate, endDate]);

  const handleResetFilters = () => {
    setStartDate("");
    setEndDate("");
  };

  return (
    <>
      {/* <TableData headerData={headerData} /> */}

      <h2 className="text-xl font-bold mb-4">Payment Verification</h2>
      <div className="flex gap-5 justify-end mb-4">
        <TextField
          label="Start Date"
          type="date"
          size="small"
          sx={{ maxWidth: "250px" }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <TextField
          label="End Date"
          type="date"
          size="small"
          sx={{ maxWidth: "250px" }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <Button
          variant="contained"
          color="success"
          onClick={handleResetFilters}
        >
          <span className="  text-white">Reset Filter</span>
        </Button>
      </div>
      {datafetched && <PaymentTable headerData={headerData} />}
    </>
  );
};

export default PaymentVerfication;
