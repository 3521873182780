import React, { useContext, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye } from "react-icons/fa";
import {
  IconButton,
  Button,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import DummyImage from "../assets/images/dummy.jpg";
import AddReceipt from "./AddReceipt";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ReceiptTable = (props) => {
  const axiosInstance = useAxiosConfig();
  const headerData = props.headerData;
  const { receiptData, setReceiptData } = useContext(AuthContext);
  const { adminRole } = useContext(AuthContext);
  const { receiptPagination, setReceiptPagination } = useContext(AuthContext);

  const [currentType, setCurrentType] = useState(props.type || "manual");

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

 
   const handleChange = async (event, value) => {
     setReceiptPagination({
       currentPage: value,
       totalPages: receiptPagination.totalPages,
       totalCounts: receiptPagination.totalCounts,
     });

     try {
       const response = await axiosInstance.get(
         `api/admin/payments/view-all-recipts?page=${value}&type=${currentType}&startDate=${startDate}&endDate=${endDate}`
       );
       setReceiptData(response.data.receipts);
     } catch (error) {
       console.error("Error fetching data:", error);
     }
   };



 useEffect(() => {
    setCurrentType(props.type || "manual");
  }, [props.type]);

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "info" });
  };
  // to hide actions
  const showActions = currentType === "manual";

  const handleDeleteClick = (itemId) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      const body = { receiptId: deleteItemId };
      const response = await axiosInstance.post(
        "api/admin/payments/reject-request",
        body
      );

      const updatedReceipts = receiptData.map((receipt) => {
        if (receipt._id === deleteItemId) {
          return { ...receipt, status: response.data.receipt.status };
        }
        return receipt;
      });

      setReceiptData(updatedReceipts);
      setNotification({
        open: true,
        message: "Receipt rejected successfully.",
        severity: "success",
      });
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting data:", error);

      if (error.response?.data?.message === "Receipt already rejected") {
        setNotification({
          open: true,
          message: "Receipt is already rejected.",
          severity: "warning",
        });
        setIsDeleteModalOpen(false);
      } else {
        setNotification({
          open: true,
          message: "Error deleting data.",
          severity: "error",
        });
      }
      setIsDeleting(false);
    }
  };

  const handleEyeClick = (image) => {
    setLoading(true);
    setSelectedImage(image || DummyImage);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    if (selectedImage) {
      const img = new Image();
      img.src = `https://maidan-s3.s3.us-west-2.amazonaws.com/${selectedImage}`;
      img.onload = () => setLoading(false);
      img.onerror = () => setLoading(false);
    }
  }, [selectedImage]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Rejected":
      case "FAILED":
        return { color: "red", fontWeight: "bold" };
      case "Approved":
      case "SUCCESS":
        return { color: "green", fontWeight: "bold" };
      case "PENDING":
        return { color: "orange", fontWeight: "bold" };
      default:
        return {};
    }
  };

  const getUserData = (data) => {
    const user = currentType === "manual" ? data.userId : data.userKey;
    return {
      fullName: user?.fullName || "N/A",
      phoneNo: user?.phone_no || "N/A",
    };
  };

  return (
    <>
      <Snackbar
        open={notification.open}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      {/* Add Receipt Button */}

      <div className="absolute top-20 right-[4%] flex gap-5">
        {/* <div className="flex gap-5 justify-end mb-4"> */}
        <TextField
          type="text"
          label="Search by name"
          value={searchQuery}
          onChange={handleSearchInputChange}
          size="small"
        />
        <TextField
          label="Start Date"
          type="date"
          size="small"
          sx={{ maxWidth: "180px" }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <TextField
          label="End Date"
          type="date"
          size="small"
          sx={{ maxWidth: "180px" }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />
        <Button
          variant="contained"
          color="success"
          onClick={handleOpenAddDialog}
          disabled={adminRole !== "Super Admin"}
          sx={{
            backgroundColor:
              adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
            boxShadow: "none",
            "&:hover": {
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
            },
            cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
          }}
        >
          Add Receipt
        </Button>
      </div>

      <AddReceipt
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        axiosInstance={axiosInstance}
        setNotification={setNotification}
      />
      <TableContainer
        component={Paper}
        sx={{
          marginX: "auto",
          maxWidth: {
            sm: "98%",
            // md: "80%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "76vh",
            sm: "90vh",
          },
          overflowY: "auto",
          overflowX: "scroll",
          position: "relative",
          top: "1rem",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              {showActions && (
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {receiptData.map((data) => {
              const user = getUserData(data);
              return (
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left">{user.fullName}</TableCell>
                  <TableCell align="left">{data?.amount}</TableCell>
                  <TableCell align="left" style={getStatusStyle(data?.status)}>
                    {data.status}
                  </TableCell>
                  <TableCell align="left">{user.phoneNo}</TableCell>
                  {showActions && (
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleEyeClick(data.transactionImage)}
                      >
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteClick(data._id)}
                        disabled={adminRole !== "Super Admin"}
                        style={{
                          cursor:
                            adminRole === "Super Admin"
                              ? "pointer"
                              : "not-allowed",
                          opacity: adminRole === "Super Admin" ? 1 : 0.5,
                        }}
                      >
                        <DeleteForeverIcon
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: adminRole === "Super Admin" ? "red" : "grey",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              padding: "1rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              zIndex: 1000,
            }}
          >
            <Pagination
              count={receiptPagination.totalPages}
              page={receiptPagination.currentPage}
              onChange={handleChange}
            />
          </Box>
        </Table>
      </TableContainer>

      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3 max-[500px]:relative"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
            "@media (maxWidth: 600px)": {
              maxWidth: 250,
            },
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className="text-red-700">Confirm Rejection!</h2>
            {isDeleting ? (
              <p className="sm:text-sm">Rejecting...</p>
            ) : (
              <p className="sm:text-sm">
                Are you sure you want to reject this receipt?
              </p>
            )}
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleConfirmDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Rejecting..." : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
                disabled={isDeleting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      <Dialog
        onClose={handleCloseImageDialog}
        open={openImageDialog}
        sx={{ minWidth: "300px" }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          Receipt
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <img
              src={`https://maidan-s3.s3.us-west-2.amazonaws.com/${selectedImage}`}
              alt={`Image ${selectedImage}`}
              style={{ width: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseImageDialog}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReceiptTable;
