import React, { useContext, useEffect, useState } from "react";
import MatchTable from "../component/MatchTable";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";

const MatchProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { setMatchData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [totalPages, setTotalPages] = useState("");

  const headerData = [
    "MatchType",
    "Organizer",
    "Status",
    "GroundName",
    "Date",
    "StartTime",
    "EndTime",
  ];
  useEffect(() => {
    fetchApi();
  }, [setMatchData]);

  const fetchApi = async (page) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `api/admin/allmatches?page=${page || 1}`
      );
      setMatchData(response.data.matches);
      // setCurrentPage(response.data.matches);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };
  return (
    <>
      <h2 className="text-xl font-bold mb-4">Matches</h2>

      <MatchTable
        headerData={headerData}
        totalPages={totalPages}
        fetchApi={fetchApi}
      />
    </>
  );
};

export default MatchProfile;
