import TableData from "../component/TableData";


const PlayersProfile = () => {
;
  const headerData = [
    "",
    "FullName",
    "Email",
    "PhoneNo",
    "Role",
    "IsSuspended",
    "IsVerified",
  ];

 

  return (
    <>
      {" "}

      <TableData headerData={headerData} />
    </>
  );
};

export default PlayersProfile;
