import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineClose,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from "react-icons/ai";
import { AuthContext } from "../auth/ContextAuthentication";
import ForgetPassword from "../component/ForgetPassword";
import useAxiosConfig from "../config/AxiosConfig";

const Login = () => {
  const axiosInstance = useAxiosConfig();
  const navigate = useNavigate();
  const {
    setAuthenticated,
    setAdminRole,
    setAccessToken,
    setAdminMail,
    setforgetPassword,
    forgetPassword,
  } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [credentials, setCredentials] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrorMessage("Please Fill in the required fields");
      setCredentials(true);
      setTimeout(() => {
        setCredentials(false);
      }, 3000);
    }

    try {
      const response = await axiosInstance.post("api/admin/login", {
        email,
        password,
      });

      if (response.data.status === true) {
        localStorage.setItem("access_token", response.data.accessToken);
        localStorage.setItem("admin_role", response.data.admin?.status);
        setAccessToken(response.data.accessToken);
        setAuthenticated(true);
        setAdminRole(response.data.admin?.status);
        setAdminMail(email);
        navigate("/");
      } else {
        setErrorMessage("Invalid Credentials");
        setCredentials(true);
        setTimeout(() => {
          setCredentials(false);
        }, 3000);
      }
    } catch (error) {
      if (error.response) {
        setErrorMessage("Invalid Credentials");
        setCredentials(true);
        setTimeout(() => {
          setCredentials(false);
        }, 3000);
      }
    }
  };

  return (
    <div
      className="h-screen w-full flex items-center justify-center"
      style={{ backgroundColor: "#0C7240" }}
    >
      <div
        className="bg-white flex max-h-full flex-col justify-center px-6 py-12 lg:px-8 rounded-lg"
        style={{
          boxShadow:
            "rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px",
        }}
      >
        <div className="mx-auto " alt="Your Company">
          <svg
            width="290"
            height="48"
            viewBox="0 0 290 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M88.3423 46.8797L104.8 7.05542H116.699L117.747 22.4771C117.813 23.4133 117.856 24.415 117.884 25.4572C117.912 26.4995 117.928 27.5823 117.937 28.7088C118.253 27.8336 118.613 26.9753 119.017 26.1375C119.452 25.2201 120.051 24.0759 120.815 22.7049L129.636 7.05542H141.463L138.205 46.8797H128.332L130.229 27.8912C130.342 26.8198 130.472 25.7641 130.619 24.7239C130.766 23.6785 130.931 22.6331 131.115 21.5815C130.372 23.3164 129.509 24.9973 128.532 26.6118L128.026 27.5105L116.842 46.8797H110.361L108.654 27.5324C108.556 26.5525 108.482 25.5685 108.432 24.5803C108.382 23.5922 108.346 22.5999 108.323 21.6034C107.999 22.7788 107.667 23.8938 107.328 24.9486C106.989 26.0033 106.653 26.9821 106.32 27.885L98.7618 46.8735L88.3423 46.8797Z"
              fill="#0C7240"
            />
            <path
              d="M161.881 43.1855C160.582 44.6487 159.009 45.8426 157.25 46.6992C155.586 47.492 153.764 47.8995 151.92 47.8913C148.748 47.8913 146.208 46.6971 144.303 44.3089C142.397 41.9206 141.546 38.8199 141.75 35.0066C142.004 30.2217 143.592 26.2555 146.515 23.108C149.438 19.9604 152.955 18.3855 157.066 18.3835C158.832 18.3481 160.579 18.7569 162.146 19.5724C163.724 20.468 165.064 21.7298 166.053 23.2515L166.896 19.2572H176.139L170.288 46.8865H161.207L161.881 43.1855ZM151.621 34.0517C151.525 35.8179 151.987 37.2398 153.006 38.3175C154.025 39.3951 155.419 39.9328 157.188 39.9308C158.127 39.938 159.056 39.7398 159.91 39.3501C160.765 38.9604 161.524 38.3885 162.134 37.6746C163.5 36.1705 164.244 34.2878 164.365 32.0264C164.454 30.3122 163.99 28.9215 162.973 27.8543C161.956 26.7871 160.581 26.2535 158.848 26.2535C157.905 26.2419 156.971 26.4339 156.109 26.8162C155.248 27.1986 154.479 27.7624 153.855 28.469C152.486 29.9482 151.741 31.8091 151.621 34.0517Z"
              fill="#0C7240"
            />
            <path
              d="M174.853 46.8798L180.679 19.2506H190.496L184.645 46.8798H174.853ZM181.84 11.0186C181.906 9.61869 182.523 8.30164 183.556 7.35512C184.05 6.86266 184.636 6.47352 185.282 6.21044C185.928 5.94736 186.619 5.81563 187.316 5.82293C188.001 5.79667 188.684 5.91447 189.32 6.16873C189.957 6.42299 190.533 6.80803 191.011 7.29895C191.485 7.78812 191.848 8.37351 192.075 9.01535C192.302 9.65719 192.388 10.3404 192.328 11.0186C192.252 12.4429 191.634 13.784 190.599 14.7661C189.565 15.7482 188.194 16.2966 186.767 16.2986C186.091 16.3121 185.42 16.1797 184.8 15.9105C184.179 15.6412 183.624 15.2414 183.172 14.7383C182.703 14.2453 182.343 13.6598 182.113 13.0192C181.884 12.3787 181.791 11.6973 181.84 11.0186Z"
              fill="#0C7240"
            />
            <path
              d="M227.696 4.48096L218.756 46.8797H209.566L210.33 43.2942C209.006 44.7519 207.418 45.9453 205.649 46.811C203.985 47.6014 202.165 48.0077 200.323 48C197.148 48 194.609 46.8006 192.706 44.402C190.802 42.0033 189.948 38.9078 190.144 35.1153C190.399 30.3304 191.984 26.3778 194.896 23.2572C197.809 20.1367 201.334 18.5691 205.472 18.5546C207.206 18.5234 208.922 18.9222 210.464 19.7154C212.069 20.6192 213.454 21.8658 214.521 23.3664L214.565 22.5738C214.592 22.0288 214.649 21.4588 214.736 20.8638C214.824 20.2688 214.928 19.6624 215.049 19.0445L218.125 4.49343L227.696 4.48096ZM200.235 33.8858C200.142 35.6562 200.604 37.0781 201.621 38.1515C202.638 39.225 204.032 39.7628 205.802 39.7649C206.744 39.771 207.675 39.5724 208.532 39.1828C209.388 38.7933 210.15 38.222 210.764 37.5087C212.139 36.0046 212.887 34.1219 213.008 31.8605C213.101 30.1463 212.633 28.7567 211.603 27.6915C210.574 26.6264 209.203 26.0928 207.491 26.0907C206.545 26.0731 205.607 26.2591 204.739 26.636C203.872 27.0129 203.096 27.5719 202.463 28.2751C201.115 29.7563 200.377 31.6265 200.248 33.8858H200.235Z"
              fill="#0C7240"
            />
            <path
              d="M244.457 43.1855C243.158 44.6495 241.583 45.8435 239.823 46.6992C238.159 47.4923 236.339 47.8998 234.496 47.8913C231.323 47.8913 228.784 46.6971 226.879 44.3089C224.973 41.9206 224.121 38.8199 224.323 35.0066C224.579 30.2217 226.168 26.2555 229.091 23.108C232.014 19.9604 235.535 18.3855 239.654 18.3835C241.42 18.3481 243.167 18.7569 244.734 19.5724C246.313 20.468 247.653 21.7298 248.641 23.2515L249.484 19.2572H258.727L252.866 46.8802H243.786L244.457 43.1855ZM234.196 34.0517C234.101 35.8179 234.562 37.2398 235.582 38.3175C236.601 39.3951 237.994 39.9328 239.76 39.9308C240.7 39.938 241.629 39.7399 242.484 39.3502C243.339 38.9605 244.099 38.3886 244.709 37.6746C246.076 36.1705 246.82 34.2878 246.941 32.0264C247.03 30.3122 246.566 28.9215 245.549 27.8543C244.532 26.7871 243.156 26.2535 241.423 26.2535C240.481 26.2419 239.547 26.4339 238.685 26.8162C237.824 27.1986 237.055 27.7624 236.431 28.469C235.06 29.9482 234.315 31.8091 234.196 34.0517Z"
              fill="#0C7240"
            />
            <path
              d="M257.235 46.8799L263.086 19.2507H272.448L271.534 23.5726C273.144 21.7503 274.719 20.4282 276.258 19.6064C277.779 18.7947 279.477 18.3743 281.201 18.3832C284.066 18.3832 286.25 19.154 287.754 20.6955C289.258 22.237 289.932 24.3934 289.776 27.1644C289.627 28.7554 289.378 30.3354 289.031 31.8951C288.999 32.0948 288.974 32.2602 288.949 32.3882L285.876 46.8862H276.096L279.176 32.2509C279.269 31.8327 279.351 31.4208 279.419 31.0214C279.488 30.6219 279.525 30.2724 279.544 29.9791C279.606 28.7787 279.336 27.8676 278.733 27.2455C278.129 26.6235 277.207 26.3114 275.965 26.3094C275.193 26.2839 274.425 26.4358 273.721 26.7533C273.016 27.0709 272.394 27.5456 271.902 28.1411C270.862 29.3644 270.064 31.3126 269.508 33.9859L266.85 46.8706L257.235 46.8799Z"
              fill="#0C7240"
            />
            <path
              d="M59.9018 0.143542L55.867 8.55652L38.0144 0L33.9795 8.41609L18.4549 0.973606L0 39.4842L15.5247 46.9235L19.5564 38.5105L37.6368 47.1763L41.6685 38.7602L56.9685 46.0934L75.4265 7.58291L59.9018 0.143542Z"
              fill="#0C7240"
            />
          </svg>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          {!forgetPassword ? (
            <form className="space-y-6" onSubmit={handleLogin}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email Address :
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full px-3  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password :
                  </label>
                </div>
                <div className="mt-2 relative">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"} // Toggle between password and text
                    autoComplete="current-password"
                    required
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full px-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)} // Toggle visibility
                  >
                    {showPassword ? (
                      <AiOutlineEyeInvisible size={20} />
                    ) : (
                      <AiOutlineEye size={20} />
                    )}
                  </div>
                </div>
                <div
                  className="py-1.5 text-blue-500 text-right cursor-pointer block w-full px-1"
                  onClick={() => setforgetPassword(!forgetPassword)}
                >
                  Forgot password?
                </div>
              </div>
              <div>
                <button
                  style={{ backgroundColor: "#0C7240" }}
                  type="submit"
                  className="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>

              {credentials ? (
                <div className="flex w-full justify-center rounded-md my-3  px-3 py-1.5 text-sm font-light leading-6 text-white bg-[#ff0033]">
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
            </form>
          ) : (
            <div className="space-y-6">
              <div
                className="bg-white flex flex-col p-6 justify-center rounded-md border-0  w-auto  mx-auto relative "
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  height: "28rem",
                }}
              >
                <ForgetPassword />
                <button>
                  <AiOutlineClose
                    size="25"
                    className="absolute top-2 right-2"
                    onClick={() => setforgetPassword(!forgetPassword)}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
