// import React from "react";
// import { useContext, useState, useEffect } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TextField,
//   Autocomplete,
// } from "@mui/material";
// import { AuthContext } from "../auth/ContextAuthentication";
// import useAxiosConfig from "../config/AxiosConfig";

// const headerData = [
//   "Player Name",
//   "Age",
//   "Contact Number",
//   "Monthly Fee",
//   "Registration Fee",
//   "Session Registered",
// ];

// function AcademyRegistrations() {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedSession, setSelectedSession] = useState(null);
//   const { academyRegistrations, setAcademyRegistrations } =
//     useContext(AuthContext);
//   const axiosInstance = useAxiosConfig();
//   const [filters, setFilters] = useState({
//     startDate: "",
//     endDate: "",
//   });

//   // Function to fetch filtered data from API
//   const fetchFilteredData = async () => {
//     try {
//       const academyId = academyRegistrations[0]?.academyId;
//       if (!academyId) return;

//       // Build query parameters
//       const params = new URLSearchParams();
//       if (selectedSession?.sessionDetails?.sessionName) {
//         params.append(
//           "sessionName",
//           selectedSession.sessionDetails.sessionName
//         );
//       }
//       if (filters.startDate) {
//         params.append("startDate", filters.startDate);
//       }
//       if (filters.endDate) {
//         params.append("endDate", filters.endDate);
//       }

//       const queryString = params.toString() ? `?${params.toString()}` : "";
//       const response = await axiosInstance.get(
//         `api/admin/academy/get-academy-registrations/${academyId}${queryString}`
//       );
//       setAcademyRegistrations(response?.data?.data || []);
//     } catch (error) {
//       console.error("Error fetching filtered data:", error);
//     }
//   };

//   // Effect to fetch data when dates or session changes
//   useEffect(() => {
//     if (filters.startDate || filters.endDate || selectedSession) {
//       fetchFilteredData();
//     }
//   }, [filters.startDate, filters.endDate, selectedSession]);

//   const handleSearchInputChange = (event) => {
//     setSearchQuery(event.target.value);
//   };

//   const handleSessionChange = (event, newValue) => {
//     setSelectedSession(newValue);
//   };

//   const handleDateChange = (field) => (event) => {
//     setFilters((prev) => ({
//       ...prev,
//       [field]: event.target.value,
//     }));
//   };

//   // Filter data based on name search (frontend filtering)
//   const getFilteredData = () => {
//     if (!searchQuery) return academyRegistrations;

//     return academyRegistrations.filter((academy) =>
//       academy?.userId?.fullName
//         ?.toLowerCase()
//         .includes(searchQuery.toLowerCase())
//     );
//   };

//   // Get unique sessions for Autocomplete
//   const uniqueSessions = Array.from(
//     new Set(academyRegistrations.map((reg) => reg.sessionDetails?.sessionName))
//   ).filter(Boolean);

//   return (
//     <div className="p-4 w-full">
//       <div className="flex justify-between mb-4">
//         <h2 className="text-xl font-bold mb-4">Academy Registrations</h2>
//         <div className="flex gap-5">
//           <TextField
//             type="text"
//             label="Search by name"
//             value={searchQuery}
//             onChange={handleSearchInputChange}
//             size="small"
//             sx={{ maxWidth: "180px" }}
//           />
//           <Autocomplete
//             options={academyRegistrations || []}
//             getOptionLabel={(option) =>
//               option.sessionDetails?.sessionName || ""
//             }
//             size="small"
//             sx={{ maxWidth: "280px" }}
//             value={selectedSession}
//             onChange={handleSessionChange}
//             renderInput={(params) => (
//               <TextField
//                 {...params}
//                 label="Select Session"
//                 InputProps={{
//                   ...params.InputProps,
//                   endAdornment: params.InputProps.endAdornment,
//                 }}
//               />
//             )}
//             isOptionEqualToValue={(option, value) =>
//               option.sessionDetails?._id === value?.sessionDetails?._id
//             }
//           />
//           <TextField
//             label="Start Date"
//             type="date"
//             value={filters.startDate}
//             onChange={handleDateChange("startDate")}
//             InputLabelProps={{ shrink: true }}
//             size="small"
//             sx={{ maxWidth: "180px" }}
//           />
//           <TextField
//             label="End Date"
//             type="date"
//             value={filters.endDate}
//             onChange={handleDateChange("endDate")}
//             InputLabelProps={{ shrink: true }}
//             size="small"
//             sx={{ maxWidth: "180px" }}
//           />
//         </div>
//       </div>
//       <TableContainer component={Paper}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               {headerData.map((header, index) => (
//                 <TableCell
//                   key={index}
//                   align="left"
//                   style={{ fontWeight: "bold" }}
//                 >
//                   {header}
//                 </TableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {getFilteredData().map((academy) => (
//               <TableRow key={academy.academyId}>
//                 <TableCell>{academy?.userId?.fullName}</TableCell>
//                 <TableCell>{academy?.playerAge}</TableCell>
//                 <TableCell>{academy?.contactNumber}</TableCell>
//                 <TableCell>{academy?.monthlyFee}</TableCell>
//                 <TableCell>
//                   {academy?.registrationFeePaid ? "Paid" : "Not Paid"}
//                 </TableCell>
//                 <TableCell>{academy?.sessionDetails?.sessionName}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </div>
//   );
// }

// export default AcademyRegistrations;



import React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Autocomplete,
} from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";

const headerData = [
  "Player Name",
  "Age",
  "Contact Number",
  "Monthly Fee",
  "Registration Fee",
  "Session Registered",
];

function AcademyRegistrations() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSession, setSelectedSession] = useState(null);
  const { academyRegistrations, setAcademyRegistrations } =
    useContext(AuthContext);
  const axiosInstance = useAxiosConfig();
  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });

  // Function to fetch filtered data from API
  const fetchFilteredData = async () => {
    try {
      const academyId = academyRegistrations[0]?.academyId;
      if (!academyId) return;

      // Build query parameters
      const params = new URLSearchParams();
      if (selectedSession?.sessionDetails?.sessionName) {
        params.append(
          "sessionName",
          selectedSession.sessionDetails.sessionName
        );
      }

      // Only add date parameters if both dates are selected
      if (filters.startDate && filters.endDate) {
        params.append("startDate", filters.startDate);
        params.append("endDate", filters.endDate);
      }

      const queryString = params.toString() ? `?${params.toString()}` : "";
      const response = await axiosInstance.get(
        `api/admin/academy/get-academy-registrations/${academyId}${queryString}`
      );
      setAcademyRegistrations(response?.data?.data || []);
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    }
  };

  // Effect to fetch data when dates or session changes
  useEffect(() => {
    // Only fetch if both dates are selected or if there's no date filter at all
    const shouldFetch =
      (filters.startDate && filters.endDate) || // both dates selected
      (!filters.startDate && !filters.endDate) || // no dates selected
      selectedSession; // session changed

    if (shouldFetch) {
      fetchFilteredData();
    }
  }, [filters.startDate, filters.endDate, selectedSession]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSessionChange = (event, newValue) => {
    setSelectedSession(newValue);
  };

  const handleDateChange = (field) => (event) => {
    setFilters((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  // Filter data based on name search (frontend filtering)
  const getFilteredData = () => {
    if (!searchQuery) return academyRegistrations;

    return academyRegistrations.filter((academy) =>
      academy?.userId?.fullName
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  };

  // Get unique sessions for Autocomplete
  const uniqueSessions = Array.from(
    new Set(academyRegistrations.map((reg) => reg.sessionDetails?.sessionName))
  ).filter(Boolean);


  return (
    <div className="p-4 w-full">
      <div className="flex justify-between mb-4">
        <h2 className="text-xl font-bold mb-4">Academy Registrations</h2>
        <div className="flex gap-5">
          <TextField
            type="text"
            label="Search by name"
            value={searchQuery}
            onChange={handleSearchInputChange}
            size="small"
            sx={{ maxWidth: "180px" }}
          />
          <Autocomplete
            options={academyRegistrations || []}
            getOptionLabel={(option) =>
              option.sessionDetails?.sessionName || ""
            }
            size="small"
            sx={{ maxWidth: "280px" }}
            value={selectedSession}
            onChange={handleSessionChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Session"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: params.InputProps.endAdornment,
                }}
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.sessionDetails?._id === value?.sessionDetails?._id
            }
          />
          <TextField
            label="Start Date"
            type="date"
            value={filters.startDate}
            onChange={handleDateChange("startDate")}
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ maxWidth: "180px" }}
            
          />
          <TextField
            label="End Date"
            type="date"
            value={filters.endDate}
            onChange={handleDateChange("endDate")}
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{ maxWidth: "180px" }}
      
          />
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell
                  key={index}
                  align="left"
                  style={{ fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getFilteredData().map((academy) => (
              <TableRow key={academy.academyId}>
                <TableCell>{academy?.userId?.fullName}</TableCell>
                <TableCell>{academy?.playerAge}</TableCell>
                <TableCell>{academy?.contactNumber}</TableCell>
                <TableCell>{academy?.monthlyFee}</TableCell>
                <TableCell>
                  {academy?.registrationFeePaid ? "Paid" : "Not Paid"}
                </TableCell>
                <TableCell>{academy?.sessionDetails?.sessionName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AcademyRegistrations;