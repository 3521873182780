import React, { useContext, useEffect, useState } from "react";
import GroundTable from "../component/GroundTable";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import { FaPlus } from "react-icons/fa";
import AddGroundModal from "../component/AddGroundModal"; // Import the modal component
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";

const GroundProfile = () => {
  const axiosInstance = useAxiosConfig();
  const headerData = [
    "Name",
    // "No of Players",
    "No of Fields",
    // "Dimensions",
    // "City",
    "IsDeleted",
  ];
  const { setGroundData } = useContext(AuthContext);
  const { setGroundPagination } = useContext(AuthContext);
  const [managersName, setManagersName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalPages, setTotalPages] = useState(false);

  const [newGroundData, setNewGroundData] = useState({
    name: "",
    owner: "",
    durationDay: 0,
    durationNight: 0,
    pricePerBookingDay: 0,
    pricePerBookingNight: 0,
    perPlayer: 0,
    noOfPlayers: 0,
    noOfFields: 0,
    dimensions: "",
    floodLights: false,
    restRooms: false,
    parkingArea: false,
    securityCameras: false,
    seatingArea: false,
    footballs: false,
    bibs: false,
    address: "",
    location: "",
    city: "",
    images: [],
  });

  const { setLoading, setError } = useContext(ErrorLoadingContext);
  useEffect(() => {
    console.log("first rndr");
    fetchGroundData();
    console.log("second rndr");
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGroundData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveGround = async () => {
    try {
      const response = await axiosInstance.post(
        "/api/admin/saveGround",
        newGroundData
      );
      if (response.status === 201) {
        // Refresh the ground data
        fetchGroundData();
      }
    } catch (error) {
      console.error("Error saving ground data:", error);
    }
  };

  const fetchGroundData = async (page) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `/api/admin/get-all-grounds?page=${page || 1}`
      );
      if (response.status === 200) {
        setGroundData(response.data.grounds);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      }
    } catch (error) {
      setError("Error fetching data");
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="text-xl font-bold mb-4">Grounds</h2>

      <GroundTable
        headerData={headerData}
        fetchGroundData={fetchGroundData}
        totalPages={totalPages}
      />

      <button onClick={handleShowModal}>
        <FaPlus className="cursor-pointer text-3xl text-green-800 fixed bottom-12 right-4" />
      </button>

      <AddGroundModal
        showModal={showModal}
        handleClose={handleCloseModal}
        handleSave={handleSaveGround}
        newGroundData={newGroundData}
        handleInputChange={handleInputChange}
        setNewGroundData={setNewGroundData}
        managersName={managersName}
        setManagersName={setManagersName}
        totalPages={totalPages}
      />
    </>
  );
};

export default GroundProfile;
