import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye, FaTrash } from "react-icons/fa";
import { IconButton, Pagination } from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication";
import MatchProfileModal from "./MatchProfileModal";
import Button from "@mui/material/Button";
import { MenuItem, TextField } from "@mui/material";
import CircleLoader from "./CircleLoader";
import useAxiosConfig from "../config/AxiosConfig";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";

const MatchTable = ({ headerData, totalPages, fetchApi }) => {
  const axiosInstance = useAxiosConfig();
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("All");
  const [matchType, setMatchtype] = useState("All");
  const { matchData, setMatchData, adminRole } = useContext(AuthContext);
  const [selectedMatch, setSelectedMatch] = useState(null); // Track the selected Ground
  const [deleteItemId, setDeleteItemId] = useState(null); // Track the item ID for deletion
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Track if the delete confirmation modal is open
  const [isDeleting, setIsDeleting] = useState(false); // Track if deletion is in progress
  const [filteredData, setFilteredData] = useState([]);
  const { loading } = useContext(ErrorLoadingContext);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchApi(page);
  }, [page]);
  // Function to open the modal with Match details
  const handleViewClick = (match) => {
    setSelectedMatch(match);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedMatch(null);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };
  const handleMatchtypeChange = (event) => {
    setMatchtype(event.target.value);
  };
  // Function to open the delete confirmation modal
  const handleDeleteClick = (itemId) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  // Function to close the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };

  // Function to handle the actual deletion
  const handleDelete = async () => {
    if (deleteItemId) {
      try {
        setIsDeleting(true); // Start the deletion process

        // Perform the deletion action using the deleteItemId
        await axiosInstance.put(`api/admin/match/delete/${deleteItemId}`);

        // Once the deletion is successful, update the matchData state accordingly
        const response = await axiosInstance.get("api/admin/allmatches");
        setMatchData(response.data.matches);

        // Close the delete confirmation modal
        handleCloseDeleteModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsDeleting(false); // End the deletion process
      }
    }
  };
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  useEffect(() => {
    // Filter the data based on the search query, city, and position
    const filtered = matchData?.filter((data) => {
      const attributes = data.attributes || {};
      // console.log(attributes.position);
      return (
        data.ground?.name &&
        data.ground?.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (status === "All" || data?.status === status) &&
        (matchType === "All" || data?.matchType === matchType)
      );
    });
    setFilteredData(filtered);
  }, [searchQuery, matchType, status, matchData]);

  const formatTime = (timeString) => {
    // Check if the timeString is in a valid ISO 8601 format
    const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    if (!iso8601Regex.test(timeString)) {
      // Handle invalid time format
      return "Invalid Time";
    }

    const date = new Date(timeString);
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };
  // Function to handle the search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = async (event, page) => {
    setPage(page);
  };

  // const renderCount = React.useRef(0);
  // console.log(`MatchTable render #${++renderCount.current}`);
  return (
    <>
      {/* Filters  */}
      <div className="absolute top-20 right-[4%]   flex flex-row justify-center gap-4">
        <div>
          <TextField
            type="text"
            label="Search by ground name"
            value={searchQuery}
            onChange={handleSearchInputChange}
            size="small"
          />
        </div>
        <div>
          <TextField
            type="text"
            value={status}
            label="status"
            size="small"
            sx={{ maxWidth: "180px" }}
            select
            onChange={handleStatusChange}
          >
            <MenuItem value="upcoming">upcoming</MenuItem>
            <MenuItem value="cancelled">cancelled</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </div>
        <div>
          <TextField
            type="text"
            value={matchType}
            size="small"
            sx={{ maxWidth: "180px" }}
            label="matchType"
            select
            onChange={handleMatchtypeChange}
          >
            <MenuItem value="openMatch">openMatch</MenuItem>
            <MenuItem value="inviteOnly">inviteOnly</MenuItem>
            <MenuItem value="All">All</MenuItem>
          </TextField>
        </div>

        <div>
          <TextField
            label="Start Date"
            type="date"
            size="small"
            sx={{ maxWidth: "180px" }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: 300 }}
          />

          <TextField
            label="End Date"
            type="date"
            size="small"
            sx={{ maxWidth: "180px" }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            style={{ width: 300 }}
          />
        </div>
      </div>
      <TableContainer
        component={Paper}
        sx={{
          maxWidth: {
            sm: "98%",
            // md: "80%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "80vh",
            sm: "90vh",
          },

          overflowY: "auto",
          position: "relative",
          top: "4rem",
          marginX: "auto",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff", // Set background color for the fixed header
              zIndex: "1",
            }}
          >
            <TableRow>
              {/* Dynamically generate header cells */}
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <CircleLoader />
          ) : (
            <TableBody>
              {filteredData?.map((data) => (
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {data.matchType}
                  </TableCell>
                  <TableCell align="left">
                    {data?.organizer?.fullName}
                  </TableCell>
                  <TableCell align="left">{data?.status}</TableCell>
                  <TableCell align="left">{data?.ground?.name}</TableCell>
                  <TableCell align="left">{formatDate(data?.date)}</TableCell>
                  <TableCell align="left">
                    {formatTime(data?.startTime)}
                  </TableCell>
                  <TableCell align="left">
                    {formatTime(data?.endTime)}
                  </TableCell>

                  <TableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <IconButton onClick={() => handleViewClick(data)}>
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      {/* <IconButton><FaRegEdit style={{ cursor: 'pointer', fontSize: 24, color: 'purple' }} /></IconButton> */}
                      <IconButton
                        onClick={() => handleDeleteClick(data._id)}
                        disabled={adminRole !== "Super Admin"}
                        style={{
                          cursor:
                            adminRole === "Super Admin"
                              ? "pointer"
                              : "not-allowed",
                          opacity: adminRole === "Super Admin" ? 1 : 0.5,
                        }}
                      >
                        <FaTrash
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "red",
                            color: adminRole === "Super Admin" ? "red" : "grey",
                          }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {selectedMatch && (
          <MatchProfileModal
            match={selectedMatch}
            handleClose={handleCloseModal}
          />
        )}
      </TableContainer>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "0.5rem",
        }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
      </div>

      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3 max-[500px]:relative"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
            "@media (maxWidth: 600px)": {
              maxWidth: 250,
            },
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className="text-red-700 ">Confirm Deletion!</h2>
            {isDeleting ? (
              <p className="sm:text-sm">Deleting...</p>
            ) : (
              <p className="sm:text-sm ">
                Are you sure you want to delete this record?
              </p>
            )}
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDelete}
                disabled={isDeleting} // Disable the button while deleting
              >
                {isDeleting ? "Deleting..." : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
                disabled={isDeleting} // Disable the button while deleting
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MatchTable;
