import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";

import BookingTable from "../component/BookingTable";
import {
  Autocomplete,
  TextField,
  CircularProgress,
  Button,
  Pagination,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import * as XLSX from "xlsx";

const SportsBookings = () => {
  const axiosInstance = useAxiosConfig();
  const { bookingData, setBookingData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [sports, setSports] = useState([]);
  const [grounds, setGrounds] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedGround, setSelectedGround] = useState(null);
  const [groundMap, setGroundMap] = useState([]);
  const [isLoadingGrounds, setIsLoadingGrounds] = useState(false);
  const [isFetchingBookings, setIsFetchingBookings] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const { adminRole } = useContext(AuthContext);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedApp, setSelectedApp] = useState("");

  const headerData = [
    "User's Name",
    "Phone No",
    "Booking date",
    "Ground Name",
    "Field",
    "Time Slot",
    "Advance Amount",
    "Remaining Amount",
    "Promo Code",
    "Manager's Name",
    "Manager's No",
    "Status",
    "Actions",
  ];

  useEffect(() => {
    fetchAllSports();
  }, []);

  useEffect(() => {
    fetchBookings();
  }, [selectedGround, selectedSport, startDate, endDate, page]);
  const fetchAllSports = async () => {
    setIsLoadingGrounds(true);
    try {
      const response = await axiosInstance.get("/api/ground/get-all-sports");
      const sports = response.data.data;
      setSports(sports);
    } catch (error) {
      setError("Error fetching sports");
    } finally {
      setIsLoadingGrounds(false);
    }
  };

  const fetchGroundsBySport = async (sportId) => {
    setIsLoadingGrounds(true);
    try {
      const response = await axiosInstance.get(
        `api/admin/sports/get-grounds-by-sports/${sportId}`
      );
      const grounds = response.data.data;
      setGrounds(grounds);
    } catch (error) {
      setError("Error fetching grounds");
      setGrounds([]);
    } finally {
      setIsLoadingGrounds(false);
    }
  };

  const fetchBookings = async () => {
    try {
      const baseUrl = "api/admin/sports/get-bookings-by-grounds-and-sports";

      // Create an object to store query parameters
      const queryParams = new URLSearchParams();

      // Add groundId parameter
      queryParams.append("groundId", selectedGround?._id || "");

      // Add sportsId parameter
      queryParams.append("sportsId", selectedSport?._id || "");

      // Add date parameters if both exist
      if (startDate && endDate) {
        queryParams.append("startDate", startDate);
        queryParams.append("endDate", endDate);
      }

      // Add page parameter
      queryParams.append("page", page);

      // Construct final URL
      const url = `${baseUrl}?${queryParams.toString()}`;
      setIsFetchingBookings(true);
      const response = await axiosInstance.get(url);
      setBookingData(response.data.data || []);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      setError("Error fetching bookings");
      setBookingData([]);
    } finally {
      setIsFetchingBookings(false);
    }
  };

  const formatTime = (timeString) => {
    const date = new Date(timeString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  console.log("byeeeeee", page);

  const handleExportToExcel = () => {
    const formattedData = bookingData.map((row) => ({
      UserName: row.owner.fullName || "N/A",
      PhoneNO: row.owner.phone_no || "N/A",
      BookingDate: formatDate(row.date) || "N/A",
      GroundName: row.ground.name || "N/A",
      Field: row.fieldData.name || "N/A",
      TimeSlot: `${formatTime(row.startTime)} - ${formatTime(row.endTime)}`,
      AdvanceAmount: row.paidAmount || "N/A",
      RemainingAmount: row.remainingAmount || "N/A",
      PromoCode: row.promoCode || "N/A",
      ManagersName: row.owner.fullName || "N/A",
      ManagersNo: row.owner.phone_no || "N/A",
      status: row.status || "N/A",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    worksheet["!cols"] = [{ wch: 30 }, { wch: 40 }, { wch: 30 }];

    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "UserName",
          "PhoneNO",
          "BookingDate",
          "GroundName",
          "Field",
          "TimeSlot",
          "AdvanceAmount",
          "RemainingAmount",
          "PromoCode",
          "ManagersName",
          "ManagersNo",
          "status",
        ],
      ],
      {
        origin: "A1",
      }
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Players");

    XLSX.writeFile(workbook, "PlayersData.xlsx");
  };

  return (
    <div className="p-4 w-full">
      <h2 className="text-xl font-bold mb-4">Bookings</h2>

      <div className="mb-4 flex gap-4">
        <Autocomplete
          options={sports}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Sport"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedSport(newValue);
            if (newValue) {
              fetchGroundsBySport(newValue._id);
            } else {
              setGrounds([]);
            }
          }}
          loading={isLoadingGrounds}
        />
        <Autocomplete
          options={grounds || "Nothing"}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Ground"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedGround(newValue);
          }}
          loading={isLoadingGrounds}
        />
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => {
            // Check if this is a real user selection rather than month navigation
            if (e.target.value !== startDate) {
              setStartDate(e.target.value);
            }
          }}
          onClick={(e) => e.stopPropagation()} // Prevent event bubbling
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => {
            if (e.target.value !== endDate) {
              setEndDate(e.target.value);
            }
          }}
          onClick={(e) => e.stopPropagation()} // Prevent event bubbling
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />
        <FormControl style={{ width: 300 }}>
          <InputLabel>Select App</InputLabel>
          <Select
            value={selectedApp}
            onChange={(e) => setSelectedApp(e.target.value)}
          >
            <MenuItem value="Ground App">Ground App</MenuItem>
            <MenuItem value="Maidan App">Maidan App</MenuItem>
          </Select>
        </FormControl>

        <Button
          variant="contained"
          onClick={handleExportToExcel}
          disabled={adminRole !== "Super Admin"}
          sx={{
            backgroundColor:
              adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
            boxShadow: "none",
            "&:hover": {
              backgroundColor:
                adminRole === "Super Admin" ? "#0C7240" : "#B0B0B0",
              boxShadow: "none",
            },
            cursor: adminRole === "Super Admin" ? "pointer" : "not-allowed",
          }}
        >
          Export
        </Button>
      </div>
      <BookingTable
        headerData={headerData}
        isLoading={isFetchingBookings}
        groundMap={groundMap}
        selectedApp={selectedApp}
      />
      <div className="w-[70%] mx-auto flex justify-center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SportsBookings;
