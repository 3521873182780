import { useContext, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import { Formik, Form, Field, FieldArray } from "formik";
// import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";

import { AuthContext } from "../../auth/ContextAuthentication";

// Validation schema using Yup
// const validationSchema = Yup.object({
//   name: Yup.string().required("Academy Name is required"),
//   openTime: Yup.string().required("Opening Time is required"),
//   closeTime: Yup.string().required("Closing Time is required"),
//   registrationFee: Yup.number()
//     .required("Registration Fee is required")
//     .positive("Registration Fee must be a positive number"),
//   sessions: Yup.array().of(
//     Yup.object().shape({
//       sessionName: Yup.string().required("Session Name is required"),
//       availableDays: Yup.string().required("Available Days are required"),
//       duration: Yup.string().required("Duration is required"),
//       timeSlotStart: Yup.string().required("Start Time is required"),
//       timeSlotEnd: Yup.string().required("End Time is required"),
//       monthlyFee: Yup.number()
//         .required("Monthly Fee is required")
//         .positive("Monthly Fee must be a positive number"),
//     })
//   ),
// });

const EditAcademyModal = ({
  isOpen,
  isClose,
  handleSubmit,
  editAcademyDetails,
}) => {
  const { academyDetails } = useContext(AuthContext);

  const initialValues = {
    name: academyDetails?.name || "",
    openTime: academyDetails?.openTime || "",
    closeTime: academyDetails?.closeTime || "",
    registrationFee: academyDetails?.registrationFee || "",
    sessions: academyDetails?.sessions || [],
  };

  useEffect(()=>{
    console.log("The id is ", academyDetails.id);
  },[])

    // const handleFormSubmit = async (values, { setSubmitting }) => {
    //   try {
    //     await editAcademyDetails(academyDetails._id, values);
    //     isClose();
    //   } catch (error) {
    //     console.error("Error updating academy:", error);
    //   } finally {
    //     setSubmitting(false);
    //   }
    // };

  return (
    <Modal open={isOpen} onClose={isClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 600,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
            color: "red",
          }}
          onClick={isClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}
        >
          Edit Academy Details
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={async (values, formikHelpers) => {
            try {
              await editAcademyDetails(academyDetails.id, values);
              isClose();
            } catch (error) {
              console.error("Error updating academy:", error);
            } finally {
              formikHelpers.setSubmitting(false);
            }
          }}
        >
          {({ values, errors, touched, handleChange, isSubmitting }) => (
            <Form>
              <Field
                as={TextField}
                fullWidth
                label="Academy Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Opening Time"
                name="openTime"
                value={values.openTime}
                onChange={handleChange}
                error={touched.openTime && Boolean(errors.openTime)}
                helperText={touched.openTime && errors.openTime}
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Closing Time"
                name="closeTime"
                value={values.closeTime}
                onChange={handleChange}
                error={touched.closeTime && Boolean(errors.closeTime)}
                helperText={touched.closeTime && errors.closeTime}
                sx={{ mb: 2 }}
              />
              <Field
                as={TextField}
                fullWidth
                label="Registration Fee"
                name="registrationFee"
                type="number"
                value={values.registrationFee}
                onChange={handleChange}
                error={
                  touched.registrationFee && Boolean(errors.registrationFee)
                }
                helperText={touched.registrationFee && errors.registrationFee}
                sx={{ mb: 2 }}
              />

              <Typography
                variant="h6"
                sx={{ mt: 3, mb: 2, fontWeight: "bold" }}
              >
                Edit Sessions
              </Typography>

              <FieldArray
                name="sessions"
                render={(arrayHelpers) => (
                  <>
                    {values.sessions.map((session, index) => (
                      <Box
                        key={index}
                        sx={{ mb: 3, pl: 2, borderLeft: "2px solid #1976d2" }}
                      >
                        <Field
                          as={TextField}
                          fullWidth
                          label={`Session Name`}
                          name={`sessions.${index}.sessionName`}
                          value={session.sessionName}
                          onChange={handleChange}
                          error={
                            touched.sessions?.[index]?.sessionName &&
                            Boolean(errors.sessions?.[index]?.sessionName)
                          }
                          helperText={
                            touched.sessions?.[index]?.sessionName &&
                            errors.sessions?.[index]?.sessionName
                          }
                          sx={{ mb: 2 }}
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          label="Available Days"
                          name={`sessions.${index}.availableDays`}
                          value={session.availableDays}
                          onChange={handleChange}
                          error={
                            touched.sessions?.[index]?.availableDays &&
                            Boolean(errors.sessions?.[index]?.availableDays)
                          }
                          helperText={
                            touched.sessions?.[index]?.availableDays &&
                            errors.sessions?.[index]?.availableDays
                          }
                          sx={{ mb: 2 }}
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          label="Duration"
                          name={`sessions.${index}.duration`}
                          value={session.duration}
                          onChange={handleChange}
                          error={
                            touched.sessions?.[index]?.duration &&
                            Boolean(errors.sessions?.[index]?.duration)
                          }
                          helperText={
                            touched.sessions?.[index]?.duration &&
                            errors.sessions?.[index]?.duration
                          }
                          sx={{ mb: 2 }}
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          label="Start Time"
                          name={`sessions.${index}.timeSlotStart`}
                          value={session.timeSlotStart}
                          onChange={handleChange}
                          error={
                            touched.sessions?.[index]?.timeSlotStart &&
                            Boolean(errors.sessions?.[index]?.timeSlotStart)
                          }
                          helperText={
                            touched.sessions?.[index]?.timeSlotStart &&
                            errors.sessions?.[index]?.timeSlotStart
                          }
                          sx={{ mb: 2 }}
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          label="End Time"
                          name={`sessions.${index}.timeSlotEnd`}
                          value={session.timeSlotEnd}
                          onChange={handleChange}
                          error={
                            touched.sessions?.[index]?.timeSlotEnd &&
                            Boolean(errors.sessions?.[index]?.timeSlotEnd)
                          }
                          helperText={
                            touched.sessions?.[index]?.timeSlotEnd &&
                            errors.sessions?.[index]?.timeSlotEnd
                          }
                          sx={{ mb: 2 }}
                        />
                        <Field
                          as={TextField}
                          fullWidth
                          label="Monthly Fee"
                          name={`sessions.${index}.monthlyFee`}
                          type="number"
                          value={session.monthlyFee}
                          onChange={handleChange}
                          error={
                            touched.sessions?.[index]?.monthlyFee &&
                            Boolean(errors.sessions?.[index]?.monthlyFee)
                          }
                          helperText={
                            touched.sessions?.[index]?.monthlyFee &&
                            errors.sessions?.[index]?.monthlyFee
                          }
                          sx={{ mb: 2 }}
                        />
                      </Box>
                    ))}
                  </>
                )}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                sx={{
                  mt: 3,
                  bgcolor: "#1976d2",
                  ":hover": { bgcolor: "#115293" },
                  display: "block",
                  ml: "auto",
                  mr: "auto",
                }}
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default EditAcademyModal;
