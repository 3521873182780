import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Chip,
  Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useAxiosConfig from "../config/AxiosConfig";

const ViewAllPromotions = () => {
  const [promotions, setPromotions] = useState([]);
  const [grounds, setGrounds] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPromotion, setSelectedPromotion] = useState(null);

  const axiosInstance = useAxiosConfig();

  useEffect(() => {
    const fetchGroundData = async () => {
      try {
        const response = await axiosInstance.get("/api/admin/getGrounds");
        if (response.status === 200) {
          const groundMap = response.data.grounds.reduce((acc, ground) => {
            acc[ground._id] = ground.name;
            return acc;
          }, {});
          setGrounds(groundMap);
        }
      } catch (error) {
        console.log("Error fetching ground data:", error);
      }
    };

    const fetchPromotions = async () => {
      try {
        const response = await axiosInstance.get("api/admin/get-promo-code");
        if (response.data.status) {
          setPromotions(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching promotions:", error);
      }
    };

    fetchGroundData();
    fetchPromotions();
  }, []);

  const handleClick = (event, promotion) => {
    setAnchorEl(event.currentTarget);
    setSelectedPromotion(promotion);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedPromotion(null);
  };

  const handleDelete = async () => {
    if (!selectedPromotion) return;
    try {
      await axiosInstance.delete(
        `api/admin/delete-promo/${selectedPromotion._id}`
      );
      setPromotions((prev) =>
        prev.filter((promo) => promo._id !== selectedPromotion._id)
      );
      handleClose();
    } catch (error) {
      console.error("Error deleting promotion:", error);
    }
  };

  return (
    <div className="w-full flex flex-col items-center justify-center gap-4 my-28">
      {promotions.map((promotion) => (
        <Card
          key={promotion._id}
          style={{
            width: "70%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.10)",
            borderRadius: "10px",
            padding: 2,
          }}
        >
          <CardContent
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <Grid style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <Typography variant="h6">{promotion.code}</Typography>
                <Chip label={promotion.discount} color="success" size="small" />
              </Grid>
              <Typography variant="body2" color="textSecondary">
                {promotion.groundIds &&
                  promotion.groundIds
                    .map((groundId) => grounds[groundId])
                    .join(", ")}
              </Typography>
              <Typography variant="body2" color="error">
                {`Expires on: ${new Date(
                  promotion.expiryDate
                ).toLocaleDateString()}`}
              </Typography>
            </div>
            <IconButton
              aria-label="more"
              onClick={(event) => handleClick(event, promotion)}
              sx={{
                padding: "18px",
                borderRadius: "50%",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </CardContent>

          <Menu
            anchorEl={anchorEl}
            open={selectedPromotion?._id === promotion._id}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </Card>
      ))}
    </div>
  );
};

export default ViewAllPromotions;
