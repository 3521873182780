import React, { useState, useEffect } from "react";
import useAxiosConfig from "../config/AxiosConfig";

const Dashboard = () => {
  const axiosInstance = useAxiosConfig();
  const [overallstats, setOverallstats] = useState({});

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get(`api/admin/overallstats`);
        if (response.data.status === true) {
          setOverallstats(response.data.overallStats);
        } else {
          console.log("Unable to fetch the data");
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchApi();
  }, []);

  return (
    <div className="p-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5">
        <div className="bg-green-200 py-4 rounded-md text-center">
          <h2 className="text-xl md:text-3xl lg:text-4xl text-gray-600 font-semibold mb-2">
            Total Users
          </h2>
          <h2 className="text-3xl md:text-4xl lg:text-5xl text-gray-600 font-bold">
            {overallstats.users}
          </h2>
          <svg
            className="w-16 h-16 md:w-20 md:h-20 lg:w-20 lg:h-20 text-gray-600 mx-auto mt-4"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0-17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
          </svg>
        </div>
        <div className="bg-red-200 py-4 rounded-md text-center">
          <h2 className="text-xl md:text-3xl lg:text-4xl text-gray-600 font-semibold mb-2">
            Total Grounds
          </h2>
          <h2 className="text-3xl md:text-4xl lg:text-5xl text-gray-600 font-bold">
            {overallstats.grounds}
          </h2>
          <svg
            className="w-16 h-16 md:w-20 md:h-20 lg:w-20 lg:h-20 text-gray-600 mx-auto mt-4"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M128 96v224H32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h96v128a32 32 0 0 0 32 32h64a32 32 0 0 0 32-32V192h96a32 32 0 0 0 32-32V96a32 32 0 0 0-32-32h-96V32a32 32 0 0 0-32-32h-64a32 32 0 0 0-32 32v96H32a32 32 0 0 0-32 32v64a32 32 0 0 0 32 32h96z"></path>
          </svg>
        </div>
        <div className="bg-blue-200 py-4 rounded-md text-center">
          <h2 className="text-xl md:text-3xl lg:text-4xl text-gray-600 font-semibold mb-2">
            Total Matches
          </h2>
          <h2 className="text-3xl md:text-4xl lg:text-5xl text-gray-600 font-bold">
            {overallstats.matches}
          </h2>
          <svg
            className="w-16 h-16 md:w-20 md:h-20 lg:w-20 lg:h-20 text-gray-600 mx-auto mt-4"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path d="M192 384h256v32H192zM448 96H256v256h192zm-85.34 130.75l-23.79 23.79a16.02 16.02 0 0 0 0 22.62l23.79 23.79a16.02 16.02 0 0 0 22.62 0l53.2-53.2a16.02 16.02 0 0 0 0-22.62l-53.2-53.2a16.02 16.02 0 0 0-22.62 0zm-192 0l-23.79 23.79a16.02 16.02 0 0 0 0 22.62l53.2 53.2a16.02 16.02 0 0 0 22.62 0l23.79-23.79a16.02 16.02 0 0 0 0-22.62l-53.2-53.2a16.02 16.02 0 0 0-22.62 0zm192 85.34l23.79-23.79a16.02 16.02 0 0 0 0-22.62l-53.2-53.2a16.02 16.02 0 0 0-22.62 0l-23.79 23.79a16.02 16.02 0 0 0 0 22.62l53.2 53.2a16.02 16.02 0 0 0 22.62 0zM192 224h32v64h-32z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
