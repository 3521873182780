import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/ContextAuthentication";

const TopNav = () => {
  const navigate = useNavigate();
  const { setAuthenticated, setOpen, open } = useContext(AuthContext);

  const handleLogout = () => {
    // Remove the access token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("admin_role");
    // Clear the user authentication
    setAuthenticated(false);
    navigate("/login");
  };
  return (
    <nav className="bg-[#0C7240]  h-14 w-screen sticky top-0 z-10 p-4 flex items-center justify-between">
      <button onClick={() => setOpen(!open)}></button>
      <div className="flex items-center gap-3">
        <img
          className="h-10 w-10 rounded-full"
          src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=2000"
          alt="User Profile"
        />
        <ul className="pt-2 pb-4 space-y-1 text-sm">
          <li className="rounded-sm" onClick={() => handleLogout()}>
            <div className="flex items-center cursor-pointer p-2 space-x-3 rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                />
              </svg>
              <span className="text-white">Logout</span>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default TopNav;
